import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function DailyClickIcon({color}: any) {


  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_2872_27946)">
        <path d="M16.2525 6.79123L1.2525 12.0412C1.11057 12.0908 0.986817 12.1819 0.8973 12.3027C0.807783 12.4235 0.756634 12.5684 0.75049 12.7186C0.744345 12.8688 0.783489 13.0174 0.862842 13.1451C0.942195 13.2728 1.05809 13.3737 1.1955 13.4347L7.6815 16.3177L10.5645 22.8037C10.6234 22.9363 10.7195 23.049 10.8412 23.1281C10.9629 23.2072 11.1049 23.2493 11.25 23.2492H11.2808C11.4305 23.2426 11.5749 23.1913 11.6952 23.1019C11.8155 23.0124 11.9063 22.889 11.9558 22.7475L17.2058 7.74749C17.2523 7.61444 17.2603 7.47099 17.2291 7.33357C17.1978 7.19615 17.1284 7.07032 17.0289 6.9705C16.9294 6.87069 16.8038 6.80093 16.6665 6.76923C16.5292 6.73752 16.3857 6.74515 16.2525 6.79123Z" fill="white"/>
        <path d="M16.5 0.75C16.3011 0.75 16.1103 0.829018 15.9697 0.96967C15.829 1.11032 15.75 1.30109 15.75 1.5V4.5C15.75 4.69891 15.829 4.88968 15.9697 5.03033C16.1103 5.17098 16.3011 5.25 16.5 5.25C16.6989 5.25 16.8897 5.17098 17.0303 5.03033C17.171 4.88968 17.25 4.69891 17.25 4.5V1.5C17.25 1.30109 17.171 1.11032 17.0303 0.96967C16.8897 0.829018 16.6989 0.75 16.5 0.75Z" fill="white"/>
        <path d="M22.5 6.75H19.5C19.3011 6.75 19.1103 6.82902 18.9697 6.96967C18.829 7.11032 18.75 7.30109 18.75 7.5C18.75 7.69891 18.829 7.88968 18.9697 8.03033C19.1103 8.17098 19.3011 8.25 19.5 8.25H22.5C22.6989 8.25 22.8897 8.17098 23.0303 8.03033C23.171 7.88968 23.25 7.69891 23.25 7.5C23.25 7.30109 23.171 7.11032 23.0303 6.96967C22.8897 6.82902 22.6989 6.75 22.5 6.75Z" fill="white"/>
        <path d="M19.2803 9.2203C19.1388 9.08368 18.9493 9.00809 18.7527 9.00979C18.5561 9.0115 18.3679 9.09038 18.2289 9.22944C18.0898 9.36849 18.011 9.5566 18.0092 9.75325C18.0075 9.9499 18.0831 10.1393 18.2198 10.2808L20.0948 12.1558C20.2362 12.2924 20.4257 12.368 20.6223 12.3663C20.8189 12.3646 21.0071 12.2857 21.1461 12.1467C21.2852 12.0076 21.364 11.8195 21.3658 11.6228C21.3675 11.4262 21.2919 11.2368 21.1553 11.0953L19.2803 9.2203Z" fill="white"/>
        <path d="M13.7197 5.7808C13.8612 5.91742 14.0507 5.99301 14.2473 5.99131C14.4439 5.9896 14.6321 5.91072 14.7711 5.77166C14.9102 5.63261 14.989 5.4445 14.9908 5.24785C14.9925 5.0512 14.9169 4.86175 14.7803 4.7203L12.9053 2.8453C12.7638 2.70868 12.5743 2.63309 12.3777 2.63479C12.1811 2.6365 11.9929 2.71538 11.8539 2.85444C11.7148 2.99349 11.636 3.1816 11.6342 3.37825C11.6325 3.5749 11.7081 3.76435 11.8448 3.9058L13.7197 5.7808Z" fill="white"/>
        <path d="M18.75 5.99945C18.9489 5.99941 19.1396 5.92036 19.2803 5.7797L21.1553 3.9047C21.2269 3.83552 21.284 3.75276 21.3233 3.66125C21.3626 3.56975 21.3833 3.47134 21.3842 3.37175C21.3851 3.27217 21.3661 3.17341 21.3284 3.08123C21.2907 2.98906 21.235 2.90532 21.1645 2.8349C21.0941 2.76448 21.0104 2.70879 20.9182 2.67108C20.826 2.63337 20.7273 2.6144 20.6277 2.61526C20.5281 2.61613 20.4297 2.63682 20.3382 2.67612C20.2467 2.71543 20.1639 2.77257 20.0947 2.8442L18.2197 4.7192C18.1149 4.82409 18.0435 4.95771 18.0146 5.10318C17.9856 5.24864 18.0005 5.39942 18.0572 5.53644C18.114 5.67347 18.2101 5.79059 18.3334 5.873C18.4567 5.95542 18.6017 5.99942 18.75 5.99945Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_2872_27946">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  );
}

export default memo(DailyClickIcon);