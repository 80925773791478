import { createSlice } from "@reduxjs/toolkit";
import {ICalendarEvent} from 'src/utils/interfaces'

type CalendarInitialProps = {
  events: ICalendarEvent[];
  resources: any;
};
const initialState: CalendarInitialProps = {
    events: [],
    resources: [],
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setEvents: (state, action) => {
      const { payload } = action;
      state.events = payload;
    },
    updateEvent: (state, action) => {
      const { id, resourceId, eventData } = action.payload;
      const eventIndex = state.events.findIndex(event => event.id === id);
      if (eventIndex !== -1) {
        state.events[eventIndex] = { ...state.events[eventIndex], ...eventData };
      }
    },
    addEvent: (state, action) => {
      const nEvent = action.payload
      state.events.push(nEvent);
    },
    deleteEvent: (state, action) => {
      const {eventId, resourceId} = action.payload;
      state.events = state.events.filter(event => event.id !== eventId);
    },
    setResources: (state, action) => {
        const { payload } = action;
        state.resources = payload;
    }, 
  },
});

const { reducer, actions } = calendarSlice;
export const {
  setEvents, updateEvent, addEvent, 
  setResources, deleteEvent
} = actions;
export default reducer;