import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function OrderCostCartIcon({color}: any) {


  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M39.8866 18H12.0098V6C12.0098 4.89544 11.1143 4 10.0098 4H4.00977V8H8.00977V32C8.00977 33.1046 8.90521 34 10.0098 34H38.4482C39.366 34 40.166 33.3754 40.3884 32.485L45.3884 12.4851C45.4282 12.3264 45.4482 12.1635 45.4482 12C45.4482 10.8954 44.5528 10 43.4482 10H16.0098V14H40.8866L39.8866 18ZM8.00977 42C8.00977 44.2092 9.80063 46 12.0098 46C14.2189 46 16.0098 44.2092 16.0098 42C16.0098 39.7908 14.2189 38 12.0098 38C9.80063 38 8.00977 39.7908 8.00977 42ZM32.0098 42C32.0098 44.2092 33.8006 46 36.0098 46C38.219 46 40.0098 44.2092 40.0098 42C40.0098 39.7908 38.219 38 36.0098 38C33.8006 38 32.0098 39.7908 32.0098 42ZM19 26C19 29.3137 21.6863 32 25 32C28.3137 32 31 29.3137 31 26C31 22.6863 28.3137 20 25 20C21.6863 20 19 22.6863 19 26ZM22.9 28.4V27.2H26.2C26.3657 27.2 26.5 27.0657 26.5 26.9C26.5 26.7343 26.3657 26.6 26.2 26.6H23.8C22.9716 26.6 22.3 25.9284 22.3 25.1C22.3 24.2716 22.9716 23.6 23.8 23.6H24.4V22.4H25.6V23.6H27.1V24.8H23.8C23.6343 24.8 23.5 24.9343 23.5 25.1C23.5 25.2657 23.6343 25.4 23.8 25.4H26.2C27.0284 25.4 27.7 26.0716 27.7 26.9C27.7 27.7284 27.0284 28.4 26.2 28.4H25.6V29.6H24.4V28.4H22.9Z" 
                fill={color}
            />
        </svg>
    </>
  );
}

export default memo(OrderCostCartIcon);