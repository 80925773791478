import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function TabularIcon({ ...other }: BoxProps) {
    const color = "currentColor"
    return (
        <Box
            component="svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...other}
        >
            <path d="M9 8H15V12H9V8Z" fill={color} />
            <path d="M17 8H23V12H17V8Z" fill={color} />
            <path d="M9 18H15V22H9V18Z" fill={color} />
            <path d="M1 8H7V12H1V8Z" fill={color} />
            <path d="M9 13H15V17H9V13Z" fill={color} />
            <path d="M23 7V5.12494C22.9985 3.39973 21.6314 2.00154 19.9445 2H4.05548C2.36862 2.00154 1.00151 3.39973 1 5.12494V7H23Z" fill={color} />
            <path d="M7 18H1V19.5001C1.00141 20.8802 2.27976 21.9988 3.8571 22H7V18Z" fill={color} />
            <path d="M1 13H7V17H1V13Z" fill={color} />
            <path d="M17 18V22H20.1429C21.7202 21.9988 22.9986 20.8802 23 19.5001V18H17Z" fill={color} />
            <path d="M17 13H23V17H17V13Z" fill={color} />
        </Box>
    );
}

export default memo(TabularIcon);