import { configureStore } from '@reduxjs/toolkit'
import filterReducer from '../reduxStore/FilterSlice/FilterSlice'
import calendarReducer from '../reduxStore/CalendarSlice/CalendarSlice' 

export const store = configureStore({
  reducer: {
    filter: filterReducer,
    calendar: calendarReducer

  },
  
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch