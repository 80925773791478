import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function VisualIcon({ ...other }: BoxProps) {
    const color = "currentColor"

    return (
        <Box
            component="svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...other}
        >
            <path d="M18 1.25H6C4.74071 1.25159 3.53345 1.75254 2.643 2.643C1.75254 3.53345 1.25159 4.74071 1.25 6V18C1.25159 19.2593 1.75254 20.4666 2.643 21.357C3.53345 22.2475 4.74071 22.7484 6 22.75H18C19.2593 22.7484 20.4666 22.2475 21.357 21.357C22.2475 20.4666 22.7484 19.2593 22.75 18V6C22.7484 4.74071 22.2475 3.53345 21.357 2.643C20.4666 1.75254 19.2593 1.25159 18 1.25ZM7.75 17C7.75 17.1989 7.67098 17.3897 7.53033 17.5303C7.38968 17.671 7.19891 17.75 7 17.75C6.80109 17.75 6.61032 17.671 6.46967 17.5303C6.32902 17.3897 6.25 17.1989 6.25 17V13C6.25 12.8011 6.32902 12.6103 6.46967 12.4697C6.61032 12.329 6.80109 12.25 7 12.25C7.19891 12.25 7.38968 12.329 7.53033 12.4697C7.67098 12.6103 7.75 12.8011 7.75 13V17ZM12.75 17C12.75 17.1989 12.671 17.3897 12.5303 17.5303C12.3897 17.671 12.1989 17.75 12 17.75C11.8011 17.75 11.6103 17.671 11.4697 17.5303C11.329 17.3897 11.25 17.1989 11.25 17V7C11.25 6.80109 11.329 6.61032 11.4697 6.46967C11.6103 6.32902 11.8011 6.25 12 6.25C12.1989 6.25 12.3897 6.32902 12.5303 6.46967C12.671 6.61032 12.75 6.80109 12.75 7V17ZM17.75 17C17.75 17.1989 17.671 17.3897 17.5303 17.5303C17.3897 17.671 17.1989 17.75 17 17.75C16.8011 17.75 16.6103 17.671 16.4697 17.5303C16.329 17.3897 16.25 17.1989 16.25 17V10C16.25 9.80109 16.329 9.61032 16.4697 9.46967C16.6103 9.32902 16.8011 9.25 17 9.25C17.1989 9.25 17.3897 9.32902 17.5303 9.46967C17.671 9.61032 17.75 9.80109 17.75 10V17Z" fill={color} />

        </Box>
    );
}

export default memo(VisualIcon);