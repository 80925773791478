import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

function TrendingDown({ color = '#FF5630', bgcolor = '#ff563029' }: { color?: string, bgcolor?: string }) {


  return (
    <>
    <IconButton 
        sx={{ bgcolor, mx: 0.5}} 
        size="small"
        aria-label="down"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M14.0002 7.99987C14.0002 7.63168 13.7018 7.3332 13.3336 7.3332C12.9654 7.3332 12.6669 7.63168 12.6669 7.99987V9.5332L9.84023 6.19987C9.62955 5.95362 9.2723 5.89454 8.99357 6.05987L6.1469 7.79987L3.18023 4.23987C3.0278 4.05647 2.7891 3.96833 2.55406 4.00864C2.31902 4.04896 2.12334 4.2116 2.04073 4.43531C1.95812 4.65902 2.00113 4.90981 2.15357 5.0932L5.4869 9.0932C5.69657 9.34529 6.05842 9.40748 6.34023 9.23987L9.19356 7.52654L11.8602 10.6665H10.0002C9.63204 10.6665 9.33356 10.965 9.33356 11.3332C9.33356 11.7014 9.63204 11.9999 10.0002 11.9999H13.3336C13.4156 11.9979 13.4967 11.9821 13.5736 11.9532L13.6669 11.8999C13.7017 11.8824 13.7351 11.8623 13.7669 11.8399C13.8034 11.8069 13.8349 11.7687 13.8602 11.7265C13.8826 11.697 13.9026 11.6658 13.9202 11.6332C13.9365 11.5972 13.9478 11.559 13.9536 11.5199C13.9806 11.4611 13.9964 11.3978 14.0002 11.3332V7.99987Z" fill={color} />
        </svg>
    </IconButton>
    </>
  );
}

export default memo(TrendingDown);
