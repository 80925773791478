import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function DiscountIcon({color}: any) {


  return (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
            fill={color}
            d="M46 24v5c0 8.284-9.85 15-22 15-11.933 0-21.647-6.478-21.99-14.558L2 29v-5c0 8.284 9.85 15 22 15s22-6.716 22-15ZM24 4c12.15 0 22 6.716 22 15 0 8.284-9.85 15-22 15S2 27.284 2 19c0-8.284 9.85-15 22-15Z"
            />
            <path
            fill="#fff"
            d="M28.889 28a3.111 3.111 0 1 1 0-6.222 3.111 3.111 0 0 1 0 6.222Zm-9.778-9.778a3.111 3.111 0 1 1 0-6.222 3.111 3.111 0 0 1 0 6.222Zm11.174-5.765 1.258 1.258-13.828 13.828-1.258-1.258 13.828-13.828Z"
            />
        </svg>
    </>
  );
}

export default memo(DiscountIcon);