/* eslint-disable import/no-extraneous-dependencies */
// scroll bar
import 'simplebar-react/dist/simplebar.min.css'

// lightbox
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

// map
import 'mapbox-gl/dist/mapbox-gl.css'

// editor
import 'react-quill/dist/quill.snow.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'

// React-Date-range
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-date-range/dist/styles.css' // main style file
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-date-range/dist/theme/default.css' // theme css file

// ----------------------------------------------------------------------

// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga'

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// swiper
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// routes
import Router from 'src/routes/sections'
// theme
import ThemeProvider from 'src/theme'
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top'
// components
import ProgressBar from 'src/components/progress-bar'
import MotionLazy from 'src/components/animate/motion-lazy'
import SnackbarProvider from 'src/components/snackbar/snackbar-provider'
import { SettingsProvider, SettingsDrawer } from 'src/components/settings'
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt'
// Import June SDK:
import { AnalyticsBrowser } from '@june-so/analytics-next'
import { useEffect } from 'react'
import ProductFruitsOnboarding from './product-fruits-onboarding/product-fruits'

// Extend the Window interface to include googleTranslateElementInit
declare global {
  interface Window {
    googleTranslateElementInit: () => void
    google: {
      translate: {
        TranslateElement: any // Adjust the type as per the documentation
      }
    }
  }
}

// Function to load Google Translate script
function loadGoogleTranslateScript(callback: () => void) {
  const script = document.createElement('script')
  script.src =
    'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
  script.async = true
  script.onload = callback
  document.body.appendChild(script)
}

// Function to initialize Google Translate
function initGoogleTranslate() {
  // Filtered language codes for Romanian
  const supportedLanguages = ['en', 'ro']

  // Initialize Google Translate widget
  window.googleTranslateElementInit = () => {
    // eslint-disable-next-line no-new
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en', // Default page language
        includedLanguages: supportedLanguages.join(','), // Filtered languages
        autoDisplay: false, // Disable auto display to manually render
      },
      'google_translate_element',
    )
  }
}

export default function App() {
  useEffect(() => {
    // Load Google Translate script
    loadGoogleTranslateScript(() => {
      // Initialize Google Translate after script has loaded
      initGoogleTranslate()
    })
    // Disable hover effects on elements containing original text
    const disableHoverEffects = () => {
      const elements = document.querySelectorAll(
        '.goog-te-menu-value, .goog-te-menu-value > span',
      )
      elements.forEach((element: any) => {
        element.addEventListener('mouseenter', () => {
          element.style.textDecoration = 'none' // Example: Disable text-decoration on hover
          element.style.color = 'inherit' // Example: Inherit text color
        })
      })
    }

    disableHoverEffects() // Call the function on component mount

    // Clean up function (optional)
    return () => {
      // Remove event listeners or clean up if needed
    }
  }, [])

  useScrollToTop()

  // Near the entrypoint of your app, instantiate AnalyticsBrowser:
  const analytics = AnalyticsBrowser.load({
    writeKey: 'q3ClmW5XJpuu6QHF',
  })

  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <AuthConsumer>
                  <ProductFruitsOnboarding/>
                  <Router />
                </AuthConsumer>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  )
}
