import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function PlanCampaignIcon({color}: any) {


  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <path d="M51.2714 19.9111L55.1118 16.1496C56.0431 15.2396 56.0431 13.7626 55.1118 12.8504C54.181 11.9378 52.6761 11.9378 51.7453 12.8504L47.9049 16.6119C44.1573 13.5759 39.4881 11.5926 34.3808 11.1192V8.66681H39.1429C40.4595 8.66681 41.5237 7.62134 41.5237 6.33319C41.5237 5.04547 40.4595 4 39.1429 4H24.8571C23.5405 4 22.4763 5.04547 22.4763 6.33319C22.4763 7.62134 23.5405 8.66681 24.8571 8.66681H29.6192V11.1192C16.9474 12.2975 7 22.7787 7 35.5C7 49.0099 18.2143 60 32 60C45.7857 60 57 49.0099 57 35.5C57 29.5826 54.8476 24.1506 51.2714 19.9111ZM34.3808 35.5C34.3808 36.7903 33.3144 37.8332 32 37.8332C30.6856 37.8332 29.6192 36.7903 29.6192 35.5V22.6668C29.6192 21.3765 30.6856 20.3332 32 20.3332C33.3144 20.3332 34.3808 21.3765 34.3808 22.6668V35.5Z" fill="url(#paint0_linear_744_15112)"/>
        <defs>
        <linearGradient id="paint0_linear_744_15112" x1="15.5526" y1="11.814" x2="43.4184" y2="58.3005" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFCC47"/>
        <stop offset="1" stopColor="#DDA30E"/>
        </linearGradient>
        </defs>
      </svg>
    </>
  );
}

export default memo(PlanCampaignIcon);