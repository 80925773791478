import React, { createContext, useContext, ReactNode } from 'react';
import { posthogInstance } from './posthogConfig';

interface PostHogProviderProps {
  children: ReactNode;
}

const PostHogContext = createContext(posthogInstance);
export const usePostHog = () => useContext(PostHogContext);
export const PostHogWProvider: React.FC<PostHogProviderProps> = ({ children }) => (
    <PostHogContext.Provider value={posthogInstance}>
      {children}
    </PostHogContext.Provider>
  );