// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

// export const HOST_API = process.env.REACT_APP_HOST_API;
export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.dashboard; // as '/dashboard'
