import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from "react-ga4";
import { PostHogWProvider } from './analytics/PostHogContext';
import { store } from './app/store'
import App from './App';
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
ReactGA.initialize('G-DH5CL15V94')
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <PostHogWProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </PostHogWProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
};

