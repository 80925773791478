import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// auth
import { AuthGuard, CheckPermission } from 'src/auth/guard'
// layouts
import DashboardLayout from 'src/layouts/dashboard'
// components
import { LoadingScreen, SplashScreen } from 'src/components/loading-screen'
import { paths } from '../paths'
import { RequiredPerm } from '../permissions'

// ----------------------------------------------------------------------

// OVERVIEW
const ProfilePage = lazy(() => import('src/pages/management/profile'))
const CreditNotesPage = lazy(() => import('src/pages/management/credit-notes'))
const BudgetPage = lazy(() => import('src/pages/marketing/budget'))
// ----------------------------------------------------------------------

export const managementRoutes = [
  {
    path: paths?.management?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Management}>
            <ProfilePage /> 
          </CheckPermission>
        ),
        index: true,
      },
      {
        path: paths?.management?.credit_notes,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Management}>
            <CreditNotesPage /> 
          </CheckPermission>
        ),
        index: true,
      },

      {
        path: paths?.management?.budget,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Budget}>
            <BudgetPage />
          </CheckPermission>
        ),
      },
      {
        path: paths?.management?.profile,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Management}>
            <ProfilePage />          
          </CheckPermission>
        ),
      },
    ],
  },
]
