import { Alert } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";

type AlertBoxProps = {
  isVisible: boolean;
  cause: 'stripe' |'calendar';
  type?: 'success' | 'error' | 'warning' | 'info';
}

type CauseTexts = {
  [key: string]: any;
};

const AlertBox: React.FC<AlertBoxProps> = ({ isVisible, cause, type = 'warning' }) => {
  
  const causeTexts: CauseTexts = {
    default: 'An unexpected error occurred.',
    // eslint-disable-next-line no-useless-concat
    stripe: (
      <Stack
        sx={{
          textAlign: 'center',
          width: '100%'
        }}
      >
            Payment Failed: We were unable to process your payment. Please verify your payment details and try again or use a different payment method.
            <br />
            If the issue is not, resolved within 7 days, your access to the dashboard will be suspended.
        </Stack>
    ),
    calendar: (
      <Stack
        sx={{
          textAlign: 'center',
          width: '100%'
        }}
      >
           Your calendar has not been generated yet. Please click the Generate Calendar button to proceed.
        </Stack>
    )
  }

  const message = causeTexts[cause] as any || causeTexts.default; // Providing a default message if key is not found

  return (
     isVisible ? (
      <Container>
        <Alert
          sx={
            type === 'info' ?
            {
              background: '#CAFDF5',
              marginBottom: 2,
              alignItems: 'center',
              fontWeight: 600,
              color: '#212B36',
              '& .MuiAlert-icon': {
                color: '#00B8D9',
              },
              '& .MuiAlert-message': {
                width: '100%'
              },
            }
            :{
            background: '#FAEDED',
            marginBottom: 2,
            alignItems: 'center',
            fontWeight: 600,
            color: '#212B36',
            '& .MuiAlert-icon': {
              color: '#C92222',
            },
            '& .MuiAlert-message': {
              width: '100%'
            },
          }
        
        }
          severity={type}
        >
            {message}
        </Alert>
    </Container>
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ) : <></>
  );
};

export default AlertBox;