import { memo } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function GrowDashLogo({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        color: 'background.paper',
        ...sx,
      }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="126" height="34" viewBox="0 0 126 34" fill="none">
            <path d="M15.5637 8.67079V24.9802C15.5637 27.8856 14.8806 30.0817 13.5144 31.5687C12.1488 33.0554 10.1217 33.799 7.43284 33.799C6.14131 33.799 4.93435 33.6342 3.81196 33.3047C2.68934 32.9757 1.70466 32.5046 0.857936 31.8916L2.35055 28.6569C3.09198 29.0659 3.90214 29.3951 4.78104 29.6446C5.65969 29.8947 6.46961 30.0198 7.21056 30.0198C8.56571 30.0198 9.57134 29.5485 10.228 28.6057C10.8843 27.6635 11.2126 26.2395 11.2126 24.3332V24.0949C10.3235 25.1847 8.9257 25.7295 7.01947 25.7295C4.77519 25.7295 3.04397 24.9749 1.82604 23.4658C0.608598 21.9561 0 19.7937 0 16.9786C0 14.209 0.582275 12.0753 1.74707 10.5771C2.91162 9.07918 4.55291 8.33008 6.67045 8.33008C8.8516 8.33008 10.4398 9.12438 11.4349 10.713H11.4983L11.7839 8.67079H15.5637ZM11.0537 16.1613C11.0537 14.754 10.7888 13.6927 10.2592 12.9778C9.73001 12.2629 8.9469 11.9055 7.90958 11.9055C6.78695 11.9055 5.93462 12.3424 5.35235 13.2161C4.76983 14.0898 4.47881 15.3782 4.47881 17.081C4.47881 20.3949 5.61168 22.0517 7.87741 22.0517C8.91473 22.0517 9.70345 21.7225 10.2436 21.064C10.7837 20.4056 11.0537 19.4524 11.0537 18.2045V16.1613Z" fill="#02006D"/>
            <path d="M16.9613 12.8242C16.9613 10.8948 16.9293 9.51056 16.8657 8.67079H21.1535C21.2385 9.55576 21.2812 10.5541 21.2812 11.6662V12.0069H21.4079C21.8101 10.8948 22.5352 10.0041 23.583 9.33445C24.6313 8.66504 25.8331 8.33008 27.1882 8.33008C27.4637 8.33008 27.792 8.36405 28.1729 8.43146V12.2117C28.1305 12.1887 27.919 12.1772 27.5382 12.1772C26.458 12.1772 25.4519 12.4725 24.5199 13.0625C23.5883 13.6527 22.8474 14.4358 22.297 15.4119C21.7464 16.3881 21.4713 17.4437 21.4713 18.5787V25.695H16.9613V12.8242Z" fill="#02006D"/>
            <path d="M36.2094 26.0357C33.5834 26.0357 31.5346 25.2527 30.0625 23.6863C28.5908 22.1201 27.8552 19.952 27.8552 17.1824C27.8552 14.4136 28.5908 12.2459 30.0625 10.6795C31.5346 9.11341 33.5834 8.33008 36.2094 8.33008C38.8344 8.33008 40.8827 9.11341 42.3543 10.6795C43.8265 12.2459 44.5625 14.4136 44.5625 17.1824C44.5625 19.952 43.8265 22.1201 42.3543 23.6863C40.8827 25.2527 38.8344 26.0357 36.2094 26.0357ZM36.2094 22.4603C37.4163 22.4603 38.3584 21.9952 39.0357 21.0651C39.7135 20.1344 40.0525 18.84 40.0525 17.1824C40.0525 15.5256 39.7135 14.232 39.0357 13.3018C38.3584 12.3711 37.4163 11.9055 36.2094 11.9055C35.0024 11.9055 34.0599 12.3711 33.3821 13.3018C32.7048 14.232 32.3662 15.5256 32.3662 17.1824C32.3662 18.84 32.7048 20.1344 33.3821 21.0651C34.0599 21.9952 35.0024 22.4603 36.2094 22.4603Z" fill="#02006D"/>
            <path d="M66.3513 8.67078L62.2537 25.695H58.315L55.361 13.9142L52.4079 25.695H48.4692L44.3716 8.67078H49.0405L50.8509 18.3749L53.3926 8.67078H57.3303L59.8719 18.3749L61.6824 8.67078H66.3513Z" fill="#02006D"/>
            <path d="M77.6857 25.5369L78.1049 23.1425C77.3808 24.0847 76.5689 24.7776 75.6696 25.2212C74.77 25.6644 73.8601 25.886 72.9398 25.886C72.1747 25.886 71.4691 25.7475 70.8232 25.471C70.1771 25.1938 69.6679 24.8168 69.2955 24.3402C68.7164 23.5976 68.2896 22.7053 68.0154 21.6636C67.7417 20.6213 67.605 19.5185 67.605 18.3547C67.605 17.8781 67.6308 17.3519 67.683 16.7755C68.1685 12.9845 69.3988 10.2355 71.374 8.52832C71.901 8.07421 72.4799 7.73898 73.1104 7.5229C73.7414 7.30708 74.3878 7.1989 75.0495 7.1989C75.6077 7.1989 76.1453 7.26553 76.6621 7.39852C77.1792 7.53178 77.6448 7.71468 78.0581 7.94722C78.8751 8.41283 79.5264 9.12195 80.0119 10.0751L81.7024 0H83.548L79.4074 25.5369H77.6857ZM69.5743 19.9339C69.6777 21.2084 70.0291 22.228 70.6292 22.993C71.229 23.7581 72.1286 24.1406 73.3278 24.1406C74.3827 24.1406 75.3286 23.8553 76.1658 23.2846C77.0035 22.7134 77.722 21.9236 78.3214 20.9153L79.6707 12.3692C79.3294 11.161 78.7815 10.28 78.0269 9.72604C77.2724 9.1716 76.4295 8.89412 75.499 8.89412C74.9094 8.89412 74.3381 8.99706 73.785 9.20243C73.2318 9.40728 72.7743 9.67588 72.4123 10.0082C71.6368 10.7398 71.0165 11.671 70.5512 12.8019C70.0864 13.9322 69.7713 15.1346 69.6055 16.4097C69.5436 17.1413 69.5129 17.8285 69.5129 18.4707C69.5129 19.0366 69.5334 19.5242 69.5743 19.9339Z" fill="#02D2C5"/>
            <path d="M92.9092 25.5369C92.8675 25.2819 92.8468 24.9995 92.8468 24.6893C92.8468 24.5007 92.8519 24.362 92.8624 24.2734L92.9862 23.0265C92.2107 23.9353 91.3371 24.636 90.3656 25.1293C89.3939 25.6226 88.3602 25.8693 87.2644 25.8693C86.3745 25.8693 85.5651 25.6807 84.8359 25.3039C84.1071 24.9271 83.5285 24.3894 83.0995 23.6912C82.6705 22.9931 82.4561 22.1787 82.4561 21.2477L82.4717 20.8986C82.5645 19.4906 83.0269 18.349 83.859 17.4737C84.6916 16.5979 85.7747 15.9661 87.1084 15.5778C88.349 15.2233 89.5486 15.0459 90.7069 15.0459L94.1191 15.0626L94.3979 13.0173C94.4186 12.7296 94.4291 12.5247 94.4291 12.4027C94.4291 11.2943 94.1057 10.4271 93.4591 9.80137C92.8129 9.17507 91.9265 8.86179 90.7995 8.86179C90.0656 8.86179 89.3627 9.0034 88.6907 9.28611C88.0185 9.5683 87.4448 9.984 86.969 10.533C86.4937 11.0814 86.1786 11.7377 86.0233 12.502H84.209C84.3428 11.4046 84.7484 10.4543 85.4257 9.65087C86.1028 8.84768 86.9324 8.23836 87.9147 7.82292C88.8974 7.40696 89.9057 7.19897 90.9399 7.19897C91.9835 7.19897 92.9087 7.40147 93.7155 7.8062C94.522 8.21041 95.1501 8.79777 95.6 9.5683C96.0497 10.3383 96.2747 11.2552 96.2747 12.3191C96.2747 12.6522 96.2642 12.9018 96.2435 13.0674L94.8474 22.0953L94.7236 23.5418C94.7131 23.6416 94.708 23.7965 94.708 24.0069C94.708 24.4508 94.7543 24.8887 94.8474 25.3206L94.8172 25.5369H92.9092ZM87.5744 24.1239C88.7424 24.1239 89.8199 23.808 90.8073 23.176C91.7951 22.5442 92.6094 21.6799 93.2504 20.583L93.8861 16.5927L90.9243 16.5759C89.9518 16.5759 88.99 16.7008 88.0385 16.9501C87.0874 17.1996 86.2761 17.6012 85.6041 18.1551C85.201 18.4875 84.8885 18.9171 84.6662 19.4438C84.4439 19.97 84.3328 20.5104 84.3328 21.0648C84.3328 21.4857 84.4001 21.879 84.5346 22.2448C84.6691 22.6106 84.8653 22.921 85.1235 23.176C85.765 23.808 86.5819 24.1239 87.5744 24.1239Z" fill="#02D2C5"/>
            <path d="M108.055 20.8316C108.065 20.7543 108.071 20.638 108.071 20.4825C108.071 19.5628 107.722 18.8537 107.024 18.3546C106.326 17.8559 105.429 17.4514 104.333 17.1412C103.33 16.8753 102.466 16.562 101.743 16.2017C101.019 15.8416 100.43 15.3734 99.975 14.797C99.7578 14.5198 99.5899 14.1819 99.471 13.7832C99.352 13.384 99.2926 12.957 99.2926 12.5019C99.2926 11.937 99.3854 11.3773 99.5714 10.8234C99.7571 10.2689 100.026 9.79236 100.378 9.39364C100.977 8.69497 101.725 8.15724 102.619 7.781C103.513 7.40422 104.436 7.21558 105.388 7.21558C106.442 7.21558 107.408 7.42095 108.287 7.83116C109.166 8.24086 109.853 8.85017 110.35 9.65911C110.847 10.468 111.064 11.4437 111.001 12.5855H109.156C109.197 11.8317 109.055 11.1777 108.73 10.6238C108.404 10.0693 107.952 9.64526 107.373 9.35184C106.794 9.05789 106.152 8.91079 105.449 8.91079C104.736 8.91079 104.046 9.04117 103.378 9.30167C102.712 9.56243 102.166 9.96716 101.743 10.5161C101.319 11.0646 101.107 11.7376 101.107 12.5353C101.107 13.2447 101.373 13.8214 101.905 14.265C102.438 14.7082 103.34 15.1126 104.612 15.4784C105.987 15.8777 107.049 16.3046 107.799 16.7587C108.548 17.2131 109.099 17.7563 109.45 18.3881C109.595 18.6431 109.706 18.948 109.784 19.3026C109.862 19.6574 109.901 20.0339 109.901 20.4324C109.901 21.031 109.807 21.6131 109.621 22.1788C109.435 22.744 109.156 23.2425 108.783 23.6744C108.142 24.395 107.357 24.9408 106.426 25.3121C105.496 25.6834 104.529 25.8692 103.527 25.8692C102.461 25.8692 101.461 25.6669 100.525 25.263C99.5894 24.8583 98.8453 24.2487 98.2923 23.434C97.739 22.6188 97.4933 21.6296 97.5552 20.4658L99.3696 20.4825C99.3696 21.6907 99.752 22.6081 100.517 23.2344C101.283 23.861 102.26 24.174 103.449 24.174C104.152 24.174 104.839 24.0548 105.512 23.8165C106.184 23.5782 106.755 23.2098 107.225 22.7108C107.695 22.212 107.972 21.5857 108.055 20.8316Z" fill="#02D2C5"/>
            <path d="M120.673 8.91084C119.515 8.91084 118.486 9.30747 117.586 10.1002C116.687 10.8924 115.927 11.9093 115.307 13.151L113.306 25.5369H111.461L115.601 0H117.447L115.632 10.5569C116.346 9.52642 117.17 8.712 118.106 8.1134C119.042 7.51506 120.063 7.21563 121.169 7.21563C122.73 7.21563 123.875 7.70344 124.604 8.67882C125.333 9.65367 125.698 10.9392 125.698 12.5354C125.698 12.9124 125.672 13.3391 125.621 13.8157L123.79 25.5369H121.944L123.775 13.7989C123.816 13.2889 123.836 12.9176 123.836 12.6848C123.836 10.1689 122.782 8.91084 120.673 8.91084Z" fill="#02D2C5"/>
        </svg>
    </Box>
  );
}

export default memo(GrowDashLogo);