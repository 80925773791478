import { memo } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

function AvgConversionRate({ ...other }: BoxProps) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7118 45.9999C19.8816 45.9999 21.6405 44.2409 21.6405 42.0712C21.6405 39.9015 19.8816 38.1426 17.7118 38.1426C15.5421 38.1426 13.7832 39.9015 13.7832 42.0712C13.7832 44.2409 15.5421 45.9999 17.7118 45.9999Z"
        fill="#22C55E"
      />
      <path
        d="M11.4287 16.1452C10.9948 16.1452 10.643 15.7934 10.643 15.3595C10.643 14.9255 10.9948 14.5737 11.4287 14.5737H26.0354C25.5671 13.0482 25.4516 11.4361 25.6976 9.85938H2.78573C2.35178 9.85938 2 10.2112 2 10.6451V13.788C2 14.222 2.35178 14.5737 2.78573 14.5737H8.28582C8.71977 14.5737 9.07155 14.9255 9.07155 15.3595C9.07155 15.7934 8.71977 16.1452 8.28582 16.1452H5.77149L11.846 21.1149C12.5779 21.7099 13.0021 22.6035 13.0002 23.5468V32.6455C12.9988 32.909 13.1318 33.1551 13.353 33.2984C13.5738 33.4472 13.8551 33.4732 14.0994 33.3676L21.9567 29.9984C22.2431 29.8716 22.4279 29.5879 22.4281 29.2747V23.5468C22.4251 22.6023 22.849 21.7071 23.5816 21.111L27.6249 17.8047C27.2516 17.2811 26.9248 16.7258 26.6483 16.1452H11.4287ZM15.3574 21.6453C15.7913 21.6453 16.1431 21.9971 16.1431 22.431C16.1431 22.865 15.7913 23.2168 15.3574 23.2168C14.9234 23.2168 14.5716 22.865 14.5716 22.431C14.5716 21.9971 14.9234 21.6453 15.3574 21.6453ZM20.0717 27.9311C19.6378 27.9311 19.286 27.5793 19.286 27.1454C19.286 26.7114 19.6378 26.3597 20.0717 26.3597C20.5057 26.3597 20.8575 26.7114 20.8575 27.1454C20.8575 27.5793 20.5057 27.9311 20.0717 27.9311ZM20.6273 22.9865L15.9129 27.7009C15.6046 27.9987 15.1145 27.9944 14.8114 27.6913C14.5084 27.3883 14.5041 26.8982 14.8019 26.5899L19.5162 21.8755C19.8245 21.5777 20.3146 21.582 20.6177 21.8851C20.9208 22.1882 20.925 22.6782 20.6273 22.9865Z"
        fill="#22C55E"
      />
      <path
        d="M36.5726 2C31.3652 2 27.1439 6.22139 27.1439 11.4287C27.1439 16.6361 31.3652 20.8575 36.5726 20.8575C41.7799 20.8575 46.0013 16.6361 46.0013 11.4287C45.9957 6.22372 41.7776 2.00563 36.5726 2ZM41.0567 8.84133L34.7709 15.1272C34.4641 15.4339 33.9667 15.4339 33.6599 15.1272L32.0884 13.5557C31.7907 13.2474 31.7949 12.7573 32.098 12.4543C32.4011 12.1512 32.8912 12.1469 33.1995 12.4447L34.2154 13.4606L39.9457 7.73031C40.254 7.43255 40.7441 7.4368 41.0472 7.73989C41.3503 8.04297 41.3545 8.53303 41.0567 8.84133Z"
        fill="#22C55E"
      />
    </svg>
  )
}

export default memo(AvgConversionRate)
