import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// auth
import { AuthGuard, CheckPermission } from 'src/auth/guard'
// layouts
import DashboardLayout from 'src/layouts/dashboard'
// components
import { LoadingScreen } from 'src/components/loading-screen'
import { paths } from '../paths'
import { RequiredPerm } from '../permissions'
// ----------------------------------------------------------------------

// OVERVIEW
const GrowthHubPage = lazy(() => import('src/pages/explore/growdash-academy'))

// ----------------------------------------------------------------------

export const exploreRoutes = [
  {
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths?.explore?.growthhub,
        element: (
            <GrowthHubPage />
        ),
      },
    ],
  },
]
