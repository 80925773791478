import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
// import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';
// layouts
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import {paths} from '../paths'

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtResetPasswordPage = lazy(() => import('src/pages/auth/jwt/reset-password'));


const authJwt = {
  path: '',
  element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
  ),
  children: [
    {
      path: paths?.auth?.jwt?.login,
      element: (
        <AuthModernCompactLayout>
          <JwtLoginPage />
        </AuthModernCompactLayout>
      ),
    },
    {
      path: paths?.auth?.jwt?.reset_password,
      element: (
        <AuthModernCompactLayout>
          <JwtResetPasswordPage />
        </AuthModernCompactLayout>
      ),
    },
  ],
};



export const authRoutes = [
  {
    path: '',
    children: [authJwt],
  },
];
