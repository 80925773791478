import { memo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function LostRevenueIcon({color}: any) {


  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path d="M44.8623 12.873C44.6177 12.3413 44.0859 12 43.5 12H40.5V4.5C40.5 3.6709 39.8291 3 39 3H30C29.1709 3 28.5 3.6709 28.5 4.5V12H25.5C24.9141 12 24.3823 12.3413 24.1377 12.873C23.8931 13.4048 23.9795 14.0317 24.3618 14.4756L33.3618 24.9756C33.646 25.3081 34.062 25.5 34.5 25.5C34.938 25.5 35.354 25.3081 35.6382 24.9756L44.6382 14.4756C45.0205 14.0317 45.1069 13.4048 44.8623 12.873Z" fill={color} />
            <path d="M28.5 27H25.5V20.4138L22.084 16.4282C20.9443 15.104 20.6777 13.2144 21.4116 11.6191C22.144 10.0283 23.7495 9 25.5 9V4.5C25.5 3.6709 24.8291 3 24 3H8.99999C8.17089 3 7.49999 3.6709 7.49999 4.5V27H4.49999C3.92284 27 3.39843 27.3311 3.14794 27.8496C2.89892 28.3696 2.96776 28.9863 3.32812 29.4375L15.3281 44.4375C15.6138 44.7935 16.0444 45 16.5 45C16.9556 45 17.3862 44.7935 17.6719 44.4375L29.6719 29.4375C30.0322 28.9863 30.1011 28.3696 29.852 27.8496C29.6016 27.3311 29.0771 27 28.5 27ZM18 25.3087V25.5C18 26.3291 17.3291 27 16.5 27C15.6709 27 15 26.3291 15 25.5H14.625C13.7959 25.5 13.125 24.8291 13.125 24C13.125 23.1709 13.7959 22.5 14.625 22.5H16.875C17.2881 22.5 17.625 22.1631 17.625 21.75C17.625 21.3369 17.2881 21 16.875 21H16.125C14.0566 21 12.375 19.3184 12.375 17.25C12.375 15.5761 13.4837 14.1724 15 13.6913V13.5C15 12.6709 15.6709 12 16.5 12C17.3291 12 18 12.6709 18 13.5C18.8291 13.5 19.5 14.1709 19.5 15C19.5 15.8291 18.8291 16.5 18 16.5H16.125C15.7119 16.5 15.375 16.8369 15.375 17.25C15.375 17.6631 15.7119 18 16.125 18H16.875C18.9434 18 20.625 19.6816 20.625 21.75C20.625 23.4239 19.5163 24.8276 18 25.3087Z" fill={color} />
        </svg>
    </>
  );
}

export default memo(LostRevenueIcon);