type PermissionType = Readonly<{
  [key: string]: string[];
}>;

export const RequiredPerm: PermissionType = Object.freeze({
  Dashboard: ["dashboard.overview", "dashboard"],
  Growth: ["dashboard.growth", "dashboard"],
  Finance: ["dashboard.finance", "dashboard"],
  Marketing: ["marketing.overview", "marketing"],
  Ads: ["marketing.ads", "marketing"],
  Discount: ["marketing.discount", "marketing"],
  Budget: ["marketing.budget", "marketing"],
  Calendar: ["marketing.calendar", "marketing"],
  Market: ["marketing.market", "marketing"],
  Management: ["management", "management.profile"],
  Profile: ["management", "management.profile"],
  CpcCreate: ["ads.campaign.create"],
  CpcEdit: ["ads.campaign.edit"],
  CpcCancel: ["ads.campaign.cancel"],
  CampaignLogs: ["ads.campaign.logs"],
  ReviewsAndRatings: ["dashboard", "dashboard.review-and-rating"],
  GrowthHub: ["growthhub"],
  Operations_Overview: ["operations", "operations.overview"],
  GrowIntel: ["dashboard.grow-intel", "growintel"],
}); 