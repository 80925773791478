import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// auth
import { AuthGuard, CheckPermission } from 'src/auth/guard'
// layouts
import DashboardLayout from 'src/layouts/dashboard'
// components
import { LoadingScreen } from 'src/components/loading-screen'
import { paths } from '../paths'
import { RequiredPerm } from '../permissions'
// ----------------------------------------------------------------------

// OVERVIEW
const OperationsOverviewPage = lazy(() => import('src/pages/opearations/overview'))
const ReviewsAndRatingsPage = lazy(() => import('src/pages/opearations/reviews-and-ratings'))

// ----------------------------------------------------------------------

export const operationsRoutes = [
  {
    path: paths?.operations?.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.Operations_Overview}>
            <OperationsOverviewPage />
          </CheckPermission>
        ),
        index: true,
      },
      {
        path: paths?.operations.reviews_and_rating,
        element: (
          <CheckPermission permissionRequired={RequiredPerm?.ReviewsAndRatings}>
            <ReviewsAndRatingsPage />
          </CheckPermission>
        ),
      },
    ],
  },
]
