import { Navigate, useRoutes } from 'react-router-dom';
// config
import PostHogPageViewTracker from 'src/analytics/PosthogPageViewTracker';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes, oldDashboardRoutes } from './dashboard';
import { marketingRoutes } from './marketing';
import {managementRoutes} from './management'
import {operationsRoutes} from './operations'
import {exploreRoutes} from './explore'
import {paths} from '../paths'
import { useAuthContext } from '../../auth/hooks';
import { growIntelRoutes } from './grow-intel';
// ----------------------------------------------------------------------

export default function Router() {
  PostHogPageViewTracker()
  const { authenticated} = useAuthContext();
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={authenticated ? paths?.marketing?.root : paths?.auth?.jwt?.login} replace />,
    },

    // ----------------------------------------------------------------------


    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    ...oldDashboardRoutes,

    ...growIntelRoutes,

    // Marketing routes
    ...marketingRoutes,

    // Management routes
    ...managementRoutes,

    // Operations routes
    ...operationsRoutes,

    // Explore routes
    ...exploreRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
