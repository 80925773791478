import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import GrowDashLogo from 'src/assets/Logo/first-logo'
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hook';
import { NavSectionVertical } from 'src/components/nav-section';
//
import { Collapse } from '@mui/material';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
  isMini: boolean;
};

export default function NavVertical({ openNav, onCloseNav, isMini=false }: Props) {

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        backgroundColor:'white',
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {(!isMini || openNav) && (
        <>
         <GrowDashLogo 
        sx={{ mt: 3, px: 4, mb: 1 }} 
      />

      <NavSectionVertical
        data={navData}
      />

      <Box sx={{ flexGrow: 1 }} />
        </>
      )
      }
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: (!isMini || openNav) ? NAV.W_VERTICAL : NAV.W_MINI },
        
        transition: 'width 0.3s ease-in-out',
      }}
    >
      
        <NavToggleButton sx={{ left: (!isMini || openNav) ? NAV.W_VERTICAL - 12 : NAV.W_MINI - 12, transition: 'left 0.3s ease-in-out', }} />

        {lgUp ? (
          <Stack
            sx={{
              height: 1,
              position: 'fixed',
              zIndex: 999,
            width: (!isMini || openNav) ? NAV.W_VERTICAL : NAV.W_MINI,
            
            transition: 'width 0.3s ease-in-out',
              borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
            }}
          >
            {renderContent}
          </Stack>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            PaperProps={{
              sx: {
                width: (!isMini || openNav) ? NAV.W_VERTICAL : NAV.W_MINI,
                transition: 'width 0.3s ease-in-out',
                
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      
    </Box>
  );
}
