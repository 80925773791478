// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import AlertBox from 'src/components/alert/alert';
import { useEffect, useState } from 'react';
import { ManagementService } from 'src/sections/management/utility/managementAPI';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(null)
  
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini/>;

  const renderNavVertical = <NavVertical isMini={isMini} openNav={nav.value} onCloseNav={nav.onFalse} />;

  const fetchSubscriptionStatus = async () => {
    try {
      const subResponse = await ManagementService.fetchSubscriptionStatus()
      setSubscriptionStatus(subResponse.status)
    } catch (error) {
      console.error('Error fetching subscription status:', error)
    }
  }
  useEffect(() => {
    fetchSubscriptionStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {renderNavVertical}

        <Main>
          <>
            <AlertBox isVisible={subscriptionStatus === 'unpaid'} type='warning' cause='stripe'/>
            {children}
          </>
        </Main>
      </>
    );
  }

  // if (isMini) {
  //   return (
  //     <>
  //       <Header onOpenNav={nav.onTrue} />

  //       <Box
  //         sx={{
  //           minHeight: 1,
  //           display: 'flex',
  //           flexDirection: { xs: 'column', md: 'row' },
  //         }}
  //       >
  //         {lgUp ? renderNavMini : renderNavVertical}

  //         <Main>
  //           <>
  //             <AlertBox isVisible={subscriptionStatus === 'unpaid'} type='warning' cause='stripe'/>
  //             {children}
  //           </>
  //         </Main>
  //       </Box>
  //     </>
  //   );
  // }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
        }}
      >
        {renderNavVertical}

        <Main>
          <>
            <AlertBox isVisible={subscriptionStatus === 'unpaid'} type='warning' cause='stripe'/>
            {children}
          </>
        </Main>
      </Box>
    </>
  );
}
