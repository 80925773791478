import { createSlice } from "@reduxjs/toolkit";
import {IBrand} from 'src/utils/interfaces';
import {jwtDecode} from '../../auth/context/jwt/utils'


// TODO: Types
type InitialProps = {
  BRANDS: IBrand[];
  LOCATIONS: IBrand[];
  CHANNELS: IBrand[];
  CITIES: any[];
    AREAS: any[];
    CUISINES: any[];
};

const token  = sessionStorage.getItem('accessToken')
const initialState: InitialProps = {
    BRANDS: token? jwtDecode(token)?.brands : [],
    LOCATIONS: [],
    CHANNELS: [],
    CITIES: [],
    AREAS: [],
    CUISINES: [],
};

const commonFilterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setBrands: (state, action) => {
      const { payload } = action;
      state.BRANDS = payload;
    },
    setLocations: (state, action) => {
        const { payload } = action;
        state.LOCATIONS = payload;
    },
    setChannels: (state, action) => {
        const { payload } = action;
        state.CHANNELS = payload;
    },
    setCities: (state, action) => {
      const { payload } = action;
      state.CITIES = payload;
    },
    setAreas: (state, action) => {
      const { payload } = action;
      state.AREAS = payload;
    },
    setCuisines: (state, action) => {
      const { payload } = action;
      state.CUISINES = payload;
    }
  },
});

const { reducer, actions } = commonFilterSlice;
export const {setLocations, setChannels, setBrands, setCities, setAreas, setCuisines} = actions;
export default reducer;